<template>
  <div
    id="collection-attribute">
    <v-data-table
      :headers="header"
      :items="data"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      :server-items-length="total"
      :height="530"
      class="elevation-1 mb-5"
      hide-default-footer
      @update:page="optionsUpdated()"
      @update:sort-by="optionsUpdated()"
      @update:sort-desc="optionsUpdated()">
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="capitalize">
              Collection
            </span>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical />
          <v-spacer />
          <search-box
            v-model="search"
            @on-search="searchCollection($event)" />
          <v-dialog
            v-model="dialog"
            max-width="500px">
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-1 ml-2"
                v-bind="attrs"
                v-on="on">
                New Item
              </v-btn>
            </template>
            <v-card>
              <v-form
                ref="attributeForm"
                @submit.prevent="save()">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row v-if="dialog">
                      <v-col :cols="12">
                        <v-text-field
                          v-model="formData.name"
                          :rules="[ ...rules.required ]"
                          :readonly="modalLoading"
                          label="Name"
                          dense />
                      </v-col>
                      <v-col :cols="12">
                        <v-text-field
                          v-model="formData.nickname"
                          :rules="[ ...rules.required, maxLengthRule(20) ]"
                          :readonly="modalLoading"
                          label="Nickname"
                          dense />
                      </v-col>
                      <v-col :cols="12">
                        <v-select
                          v-model="formData.classification"
                          :items="classifications"
                          :rules="[ ...rules.required ]"
                          :readonly="modalLoading"
                          label="Classification"
                          dense />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeFormModal()">
                    Cancel
                  </v-btn>
                  <v-btn
                    :loading="modalLoading"
                    color="blue darken-1"
                    type="submit"
                    text>
                    Save
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template
        #[`item.bcSync`]="{ item }"
        class="d-flex justify-center">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-icon
              v-if="item.bcSync && item.bcSync.sync && item.bcSync.bcLog"
              v-bind="attrs"
              color="success"
              v-on="on">
              mdi-check-circle-outline
            </v-icon>
            <v-icon
              v-else
              v-bind="attrs"
              color="error"
              v-on="on">
              mdi-sync-alert
            </v-icon>
          </template>
          <span v-if="item.bcSync && item.bcSync.updatedAt">
            Last sync date {{ item.bcSync.updatedAt | dateTimeUserFormat() }}
          </span>
          <span v-else>
            Not sync yet
          </span>
        </v-tooltip>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <gw-icon-hover
          small
          class="sku-table-bin"
          icon-name="mdi-delete"
          icon-hover="mdi-delete-empty"
          @click="removeCollection(item)" />
      </template>
    </v-data-table>
    <v-pagination
      v-if="paginationEnable"
      v-model="options.page"
      :length="pageCount"
      :disabled="loading"
      total-visible="7"
      @input="getCollection()" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ProductCollectionProvider from '@/resources/ProductCollectionProvider'
import SearchBox from '@/components/SearchBox.vue'

const ProductCollectionService = new ProductCollectionProvider()

export default {
  components: { SearchBox },
  data () {
    return {
      dialog: false,
      loading: false,
      total: 0,
      valid: false,
      pageCount: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['createdAt'],
        sortDesc: [true]
      },
      classifications: ['Core', 'Directional', 'Essential'],
      header: [
        {
          text: 'Sync',
          value: 'bcSync',
          width: 80
        },
        // {
        //   text: 'ID',
        //   value: 'id',
        //   width: 100
        // },
        {
          text: 'Name',
          value: 'name',
          width: 200
        },
        {
          text: 'Nickname',
          value: 'nickname',
          width: 200
        },
        {
          text: 'Classification',
          value: 'classification',
          width: 200
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 60,
          align: 'end'
        }
      ],
      data: [],
      isEdited: false,
      formData: {
        name: '',
        nickname: '',
        classification: ''
      },
      getting: false,
      modalLoading: false,
      search: '',
      rules: {
        required: [
          (v) => !!v || 'กรุณากรอก field นี้'
        ]
      }
    }
  },
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit Item' : 'New Item'
    },
    paginationEnable () {
      return this.pageCount > 1
    }
  },
  mounted () {
    if (this.search === '') {
      this.search = this.$route.query?.search || ''
    }
    this.getCollection(this.options)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    maxLengthRule (length) {
      return (v) => v.length <= length || `Field นี้ต้องยาวไม่เกิน ${length} ตัวอักษร`
    },
    searchCollection (value) {
      this.options.page = 1
      this.search = value

      this.$router.push({
        name: this.$route.name,
        query: { search: value }
      }).catch(() => {})

      this.getCollection()
    },
    async getCollection () {
      if (!this.getting) {
        try {
          this.getting = true
          this.loading = true
          const sortBy = this.options.sortBy[0]
          const sortOrder = this.options.sortDesc[0] ? 'desc' : 'asc'
          const { data } = await ProductCollectionService.getAll({
            ...this.options,
            sortBy,
            sortOrder,
            search: this.search
          })
          this.total = data.total
          this.pageCount = data.pages
          this.data = data.results
        } catch (error) {
          console.error('getAttribute: ', error)
          this.setErrorPage(error.code)
        } finally {
          this.loading = false
          this.getting = false
        }
      }
    },
    closeFormModal () {
      this.dialog = false
      this.isEdited = false
      this.formData = {}
    },
    editItem (item) {
      this.isEdited = true
      this.formData = {
        id: item.id,
        name: item.name,
        nickname: item.nickname
      }
      this.dialog = true
    },
    async save () {
      const valid = await this.$refs['attributeForm'].validate()
      if (!valid) return
      this.modalLoading = true
      if (this.isEdited) {
        await this.updateCollection()
      } else {
        await this.createCollection()
      }
      this.closeFormModal()
      this.modalLoading = false
      this.getCollection()
    },
    async createCollection () {
      try {
        const { data } = await ProductCollectionService.createProductCollection(this.formData)
        if (data) {
          this.$store.dispatch('Components/setSnackbar', {
            value: true,
            message: 'Success',
            type: 'success'
          })
        }
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
      }
    },
    async updateCollection () {
      try {
        const { data } = await ProductCollectionService.updateProductCollection(this.formData.id, this.formData)
        if (data) {
          this.$store.dispatch('Components/setSnackbar', {
            value: true,
            message: 'Success',
            type: 'success'
          })
        }
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
      }
    },
    async removeCollection (item) {
      this.setModal({
        value: true,
        title: 'Remove',
        message: `Do you want to remove the ${item.name}`,
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveCollection(item.id)
      })
    },
    async confirmRemoveCollection (id) {
      try {
        const { data } = await ProductCollectionService.deleteProductCollection(id)
        if (data) {
          this.$store.dispatch('Components/setSnackbar', {
            value: true,
            message: 'Success',
            type: 'success'
          })
        }
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
      }
      this.getCollection()
    }
  }
}
</script>
