var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"collection-attribute"}},[_c('v-data-table',{staticClass:"elevation-1 mb-5",attrs:{"headers":_vm.header,"items":_vm.data,"options":_vm.options,"items-per-page":_vm.options.itemsPerPage,"loading":_vm.loading,"server-items-length":_vm.total,"height":530,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){return _vm.optionsUpdated()},"update:sort-by":function($event){return _vm.optionsUpdated()},"update:sort-desc":function($event){return _vm.optionsUpdated()}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"capitalize"},[_vm._v(" Collection ")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('search-box',{on:{"on-search":function($event){return _vm.searchCollection($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-1 ml-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" New Item ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"attributeForm",on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[(_vm.dialog)?_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('v-text-field',{attrs:{"rules":[].concat( _vm.rules.required ),"readonly":_vm.modalLoading,"label":"Name","dense":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('v-col',{attrs:{"cols":12}},[_c('v-text-field',{attrs:{"rules":_vm.rules.required.concat( [_vm.maxLengthRule(20)] ),"readonly":_vm.modalLoading,"label":"Nickname","dense":""},model:{value:(_vm.formData.nickname),callback:function ($$v) {_vm.$set(_vm.formData, "nickname", $$v)},expression:"formData.nickname"}})],1),_c('v-col',{attrs:{"cols":12}},[_c('v-select',{attrs:{"items":_vm.classifications,"rules":[].concat( _vm.rules.required ),"readonly":_vm.modalLoading,"label":"Classification","dense":""},model:{value:(_vm.formData.classification),callback:function ($$v) {_vm.$set(_vm.formData, "classification", $$v)},expression:"formData.classification"}})],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeFormModal()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"loading":_vm.modalLoading,"color":"blue darken-1","type":"submit","text":""}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)]},proxy:true},{key:"item.bcSync",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.bcSync && item.bcSync.sync && item.bcSync.bcLog)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle-outline ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-sync-alert ")])]}}],null,true)},[(item.bcSync && item.bcSync.updatedAt)?_c('span',[_vm._v(" Last sync date "+_vm._s(_vm._f("dateTimeUserFormat")(item.bcSync.updatedAt))+" ")]):_c('span',[_vm._v(" Not sync yet ")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('gw-icon-hover',{staticClass:"sku-table-bin",attrs:{"small":"","icon-name":"mdi-delete","icon-hover":"mdi-delete-empty"},on:{"click":function($event){return _vm.removeCollection(item)}}})]}}],null,true)}),(_vm.paginationEnable)?_c('v-pagination',{attrs:{"length":_vm.pageCount,"disabled":_vm.loading,"total-visible":"7"},on:{"input":function($event){return _vm.getCollection()}},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }